import {Component, HostBinding, HostListener, Input, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {fuseAnimations} from '../../../../animation';
import {FuseConfigService} from '../../../../../core/service/fuse/config.service';

@Component({
  selector: 'fuse-nav-horizontal-collapse',
  templateUrl: './nav-horizontal-collapse.component.html',
  styleUrls: ['./nav-horizontal-collapse.component.scss'],
  animations: fuseAnimations
})
export class FuseNavHorizontalCollapseComponent implements OnDestroy {
  onConfigChanged: Subscription;
  fuseSettings: any;
  isOpen = false;

  @HostBinding('class') classes = 'nav-item nav-collapse';
  @Input() item: any;

  @HostListener('mouseenter')
  open() {
    this.isOpen = true;
  }

  @HostListener('mouseleave')
  close() {
    this.isOpen = false;
  }

  constructor(
    private fuseConfig: FuseConfigService
  ) {
    this.onConfigChanged =
      this.fuseConfig.onConfigChanged
        .subscribe(
          (newSettings) => {
            this.fuseSettings = newSettings;
          }
        );
  }

  ngOnDestroy() {
    this.onConfigChanged.unsubscribe();
  }
}
