import {IArticleState, State} from './state';
import * as ArticleActions from './action';

export type Action = ArticleActions.All;
export const initState: IArticleState = State() as IArticleState;

export function articleReducer(state: IArticleState = initState, action: Action) {
  switch (action.type) {

    case ArticleActions.ARTICLE_GET_LIST_SUCCESS:
      action = action as ArticleActions.ArticleGetListSuccess;
      return  state.merge({
        total: action.total,
        list: action.list
      });

    case ArticleActions.ARTICLE_SELECT:
    case ArticleActions.ARTICLE_GET_DETAIL_SUCCESS:
      action = action as ArticleActions.ArticleSelect;
      return state.merge({
        selected: action.article
      });

    case ArticleActions.ARTICLE_SELECT_CANCEL:
      return state.merge({ selected: null});

    default:
      return state;
  }
}
