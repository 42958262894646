import * as InquiryActions from './action';
import {IInquiryState, State} from './state';

export type Action = InquiryActions.All;
export const initState: IInquiryState = State() as IInquiryState;

export function inquiryReducer(state: IInquiryState = initState, action: Action) {
  switch (action.type) {

    case InquiryActions.INQUIRY_GET_LIST_SUCCESS:
      action = action as InquiryActions.InquiryGetListSuccess;
      return  state.merge({
        total: action.total,
        list: action.list
      });

    case InquiryActions.INQUIRY_SELECT:
    case InquiryActions.INQUIRY_GET_DETAIL_SUCCESS:
      action = action as InquiryActions.InquirySelect;
      return state.merge({
        selected: action.inquiry
      });

    default:
      return state;
  }
}
