import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {MatSnackBar} from '@angular/material';
import {MembershipInitialize} from './membership.action';
import {IResponse, RESPONSE_CODE} from '../../helper/response';
import * as MembershipActions from './membership.action';
import * as RouterActions from '../router/router.action';
import {MembershipApiService} from '../../api/membership-api.service';
import {SessionService} from '../../service/aram/session.service';

@Injectable()
export class MembershipEffect {

  // @Effect() Register$ = this.actions$
  //   .ofType(MembershipActions.MEMBERSHIP_REGISTER)
  //   .switchMap((action: MembershipActions.MembershipRegister) => {
  //     return this.joinApiService
  //       .join(action.user)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           return new RouterActions.Go({path: ['/membership/login']});
  //         } else {
  //           this.snackBar.open('Membership Register Failed', 'Dance', {duration: 2000});
  //           return {type: 'NULL_ACTION'};
  //         }
  //       });
  //   });

  @Effect() Login$ = this.actions$
    .ofType(MembershipActions.MEMBERSHIP_LOGIN)
    .switchMap((action: MembershipActions.MembershipLogin) => {
      return this.api
        .signin(action.id, action.pass)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            this.sessionService.init(res.data);
            return new RouterActions.Go({path: ['/dashboard']});
          } else {
            this.snackBar.open('로그인 실패!', '확인', {duration: 2000});
            return {type: 'NULL_ACTION'};
          }
        });
    });


  //
  // @Effect() MembershipInitialize$ = this.membershipActions$
  //   .ofType(MembershipActions.MEMBERSHIP_INITIALIZE)
  //   .switchMap((action: MembershipActions.MembershipInitialize) => {
  //       return this.loginApiService
  //         .initialize(action.email, action.oldPass, action.newPass)
  //         .map((res: IResponse<any>) => {
  //           if (res.code === RESPONSE_CODE.SUCCESS) {
  //             this.snackBar.open('Initialize Success!', 'Dance', {duration: 2000});
  //             return new RouterActions.Go({path: [`/membership/login`]});
  //           } else {
  //             this.snackBar.open('Initialize Failed', 'Dance', {duration: 2000});
  //             return {type: 'NULL_ACTION'};
  //           }
  //         });
  //   });
  //
  // @Effect() MembershipLogout$ = this.membershipActions$
  //   .ofType(MembershipActions.MEMBERSHIP_LOGOUT)
  //   .switchMap((action: MembershipActions.MembershipLogout) => {
  //     return this.loginApiService
  //       .logout()
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           this.sessionService.destory();
  //           this.snackBar.open('Logout Success', 'Dance', {duration: 2000});
  //           return new RouterActions.Go({path: ['/membership/login']});
  //         } else {
  //           this.snackBar.open('Logout Failed', 'Dance', {duration: 2000});
  //           return {type: 'NULL_ACTION'};
  //         }
  //       });
  //   });

  constructor(
    private actions$: Actions,
    private api: MembershipApiService,
    private sessionService: SessionService,
    private snackBar: MatSnackBar
  ) { }
}
