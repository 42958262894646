import {IProductState, State} from './state';
import * as ProductActions from './action';

export type Action = ProductActions.All;
export const initState: IProductState = State() as IProductState;

export function productReducer(state: IProductState = initState, action: Action) {
  switch (action.type) {

    case ProductActions.PRODUCT_GET_LIST_SUCCESS:
      action = action as ProductActions.ProductGetListSuccess;
      return  state.merge({
        total: action.total,
        list: action.list
      });
    case ProductActions.PRODUCT_SELECT:
      action = action as ProductActions.ProductSelect;
      return state.merge({
        selected: action.product
      });
    case ProductActions.PRODUCT_CLEAR:
      action = action as ProductActions.ProductClear;
      return state.merge({
        selected: null
      });
    default:
      return state;
  }
}
