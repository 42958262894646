import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {IResponse, RESPONSE_CODE} from '../../helper/response';
import {Clue} from '../../model/clue';
import {Converter} from '../../helper/converter';

import * as ClueActions from './action';
import {ClueApiService} from '../../api/clue-api.service';

@Injectable()
export class ClueEffect {

  @Effect() GetList$ = this.actions$
    .ofType(ClueActions.CLUE_GET_LIST)
    .switchMap((action: ClueActions.ClueGetList) => {
      return this.api
        .list(action.offset, action.count, {id: action.id, keyword: action.keyword})
        .map((res: IResponse<Clue[]>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const clues: Clue[] = Converter.jsonToInstance<Clue>(Clue, res.data);
            return new ClueActions.ClueGetListSuccess(res.data.total, clues);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Delete$ = this.actions$
    .ofType(ClueActions.CLUE_DELETE)
    .switchMap((action: ClueActions.ClueDelete) => {
      return this.api
        .delete(action.clue._id)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return action.reloadAction;
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  constructor(private actions$: Actions, private api: ClueApiService) { }
}
