import {IFoundState, State} from './state';
import * as FoundActions from './action';

export type Action = FoundActions.All;
export const initState: IFoundState = State() as IFoundState;

export function foundReducer(state: IFoundState = initState, action: Action) {
  switch (action.type) {

    case FoundActions.FOUND_GET_LIST_SUCCESS:
      action = action as FoundActions.FoundGetListSuccess;
      return  state.merge({
        total: action.total,
        list: action.list
      });

    case FoundActions.FOUND_SELECT:
    case FoundActions.FOUND_GET_DETAIL_SUCCESS:
      action = action as FoundActions.FoundSelect;
      return state.merge({
        selected: action.found
      });
    default:
      return state;
  }
}
