import {Action} from '@ngrx/store';
import {List} from 'immutable';
import {Found} from '../../model/Found';


export const FOUND_GET_LIST = 'FOUND_GET_LIST';
export class FoundGetList implements Action {
  readonly type = FOUND_GET_LIST;
  constructor(public offset: number, public count: number, public keyword?: any) {
  }
}

export const FOUND_GET_LIST_SUCCESS = 'FOUND_GET_LIST_SUCCESS';
export class FoundGetListSuccess implements Action {
  readonly type = FOUND_GET_LIST_SUCCESS;
  constructor(public total: number, public list: List<Found>) {
  }
}

export const FOUND_GET_DETAIL = 'FOUND_GET_DETAIL';
export class FoundGetDetail implements Action {
  readonly type = FOUND_GET_DETAIL;
  constructor(public id: string) {}
}

export const FOUND_GET_DETAIL_SUCCESS = 'FOUND_GET_DETAIL_SUCCESS';
export class FoundGetDetailSuccess implements Action {
  readonly type = FOUND_GET_DETAIL_SUCCESS;
  constructor(public found: Found) {
  }
}

export const FOUND_SELECT = 'FOUND_SELECT';
export class FoundSelect implements Action {
  readonly type = FOUND_SELECT;
  constructor(public found: Found) { }
}

export const FOUND_SELECT_CANCEL = 'FOUND_SELECT_CANCEL';
export class FoundSelectCancel implements Action {
  readonly type = FOUND_SELECT_CANCEL;
  constructor() { }
}

export const FOUND_CREATE = 'FOUND_CREATE';
export class FoundCreate implements Action {
  readonly type = FOUND_CREATE;
  constructor(public found: Found) {
  }
}

export const FOUND_MODIFY = 'FOUND_MODIFY';
export class FoundModify implements Action {
  readonly type = FOUND_MODIFY;
  constructor(public found: Found) {}
}

export const FOUND_DELETE = 'FOUND_DELETE';
export class FoundDelete implements Action {
  readonly type = FOUND_DELETE;
  constructor(public found: Found, public reloadAction: FoundGetList) {
  }
}

export type All =
  FoundGetList |
  FoundGetListSuccess |
  FoundGetDetail |
  FoundGetDetailSuccess|
  FoundSelect |
  FoundSelectCancel |
  FoundCreate |
  FoundModify |
  FoundDelete;
