import {Serializable} from '../helper/serializable';
import {IModel} from './interface';

export class Article extends Serializable implements IModel {

  _id: string;
  type: string;
  user: {_id: string, name: string};
  title: string;
  contents: string;
  count: number;
  images: any[];
  files: any[];
  date: Date = new Date();

  constructor(
    _id?: string,
    type?: string,
    user?: {_id: string, name: string},
    title?: string,
    contents?: string,
    count?: number,
    images?: any[],
    files?: any[],
    date?: Date
  ) {
    super();
    this._id = _id;
    this.type = type;
    this.user = user;
    this.title = title;
    this.contents = contents;
    this.count = count;
    this.images = images;
    this.files = files;
    this.date = date;
  }

  toObject(): object {
    return {
      type: this.type,
      title: this.title,
      contents: this.contents,
      images: this.images,
      files: this.files,
      date: this.date
    };
  }
}
