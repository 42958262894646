import {NgModule} from '@angular/core';

import {FuseContentModule} from 'src/app/main/content/content.module';
import {FuseFooterModule} from 'src/app/main/footer/footer.module';
import {FuseNavbarModule} from 'src/app/main/navbar/navbar.module';
import {FuseQuickPanelModule} from 'src/app/main/quick-panel/quick-panel.module';
import {FuseToolbarModule} from 'src/app/main/toolbar/toolbar.module';
import {FuseMainComponent} from './main.component';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [
    FuseMainComponent,
  ],
  imports: [
    SharedModule,
    FuseContentModule,
    FuseFooterModule,
    FuseNavbarModule,
    FuseQuickPanelModule,
    FuseToolbarModule,
  ],
  exports: [
    FuseMainComponent
  ]
})
export class FuseMainModule {
}
