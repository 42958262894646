import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';

import {ArticleApiService} from '../../api/article-api.service';
import {Article} from '../../model/article';
import {Converter} from '../../helper/converter';
import {IResponse, RESPONSE_CODE} from '../../helper/response';

import * as ArticleActions from './action';
import * as RouterActions from '../router/router.action';
import {List} from 'immutable';


@Injectable()
export class ArticleEffect {

  @Effect() Create$ = this.actions$
    .ofType(ArticleActions.ARTICLE_CREATE)
    .switchMap((action: ArticleActions.ArticleCreate) => {
      return this.api
        .add(action.article)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Go({path: ['/notice/list']});
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Modify$ = this.actions$
    .ofType(ArticleActions.ARTICLE_MODIFY)
    .switchMap((action: ArticleActions.ArticleModify) => {
      return this.api
        .modify(action.article)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Go({path: ['notice/list']});
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    })

  @Effect() GetList$ = this.actions$
    .ofType(ArticleActions.ARTICLE_GET_LIST)
    .switchMap((action: ArticleActions.ArticleGetList) => {
      return this.api
        .list(action.offset, action.count, {keyword: action.keyword, type: action.category})
        .map((res: IResponse<Article[]>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const articles: List<Article> = Converter.jsonToInstance<Article>(Article, res.data.list);
            return new ArticleActions.ArticleGetListSuccess(res.data.total, articles);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Delete$ = this.actions$
    .ofType(ArticleActions.ARTICLE_DELETE)
    .switchMap((action: ArticleActions.ArticleDelete) => {
      return this.api
        .delete(action.article._id)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return action.reloadAction;
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Get$ = this.actions$
    .ofType(ArticleActions.ARTICLE_GET_DETAIL)
    .switchMap((action: ArticleActions.ArticleGetDetail) => {
      return this.api
        .get(action.id)
        .map((res: IResponse<Article>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const article: Article = Converter.jsonToInstance<Article>(Article, res.data.value);
            return new ArticleActions.ArticleGetDetailSuccess(article);
          }
        });
    });

  constructor(private actions$: Actions, private api: ArticleApiService) { }
}
