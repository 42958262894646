import {Record, Map, List} from 'immutable';
import {Article} from '../../model/article';

export interface IArticleState extends Map<string, any> {
  list: List<Article>;
  total: number;
  selected: Article;
}

export const State = Record({
  list: List<Article>(),
  total: 0,
  selected: null
});
