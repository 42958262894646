import {NgModule} from '@angular/core';
import {SelectTypeConvert} from './selectTypeConvert';
import {HtmlPipe} from './html.pipe';
import {SelectStateConvert} from './selectStateConvert';
import {UserStateConvert} from './userStateConvert';
import {ReportStateConvert} from './reportStateConvert';

@NgModule({
  declarations: [
    SelectTypeConvert,
    SelectStateConvert,
    HtmlPipe,
    UserStateConvert,
    ReportStateConvert
  ],
  exports: [
    SelectTypeConvert,
    SelectStateConvert,
    HtmlPipe,
    UserStateConvert,
    ReportStateConvert
    ]
})
export class CommonPipeModule {}
