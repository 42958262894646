import {IModel} from './interface';
import {Serializable} from '../helper/serializable';

export class Inquiry extends Serializable implements IModel {
  _id: string = '';
  user: any = {
    name: '',
  };
  title: string = '';
  question: string = '';
  answer: any = {
    contents: ''
  };
  images: any[];
  link: string = '';
  date: Date = new Date();
  state: number;

  constructor (
    _id?: string,
    user?: string,
    title?: string,
    question?: string,
    answer?: any,
    images?: any[],
    link?: string,
    date?: Date,
    state?: number
  ) {
    super();
    this._id = _id;
    this.user = user;
    this.title = title;
    this.question = question;
    this.answer = answer;
    this.images = images;
    this.link = link;
    this.date = date;
    this.state = state;
  }

  toObject(): object {
    return {
      _id: this._id,
      title: this.title,
      question: this.question,
      answer: this.answer,
      images: this.images,
      state: this.state
    };
  }
}
