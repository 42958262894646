import {NgModule} from '@angular/core';
import {AppGrantDirective} from './grant.directive';
import {FuseIfOnDomDirective} from './fuse-if-on-dom/fuse-if-on-dom.directive';
import {FuseMatSidenavTogglerDirective} from './fuse-mat-sidenav/fuse-mat-sidenav-toggler.directive';
import {FuseMatSidenavHelperDirective} from './fuse-mat-sidenav/fuse-mat-sidenav.directive';
import {FusePerfectScrollbarDirective} from './fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {AwsSrcDirective} from './aws-src/aws-src.directive';
import {AwsProfileDirective} from './aws-src/aws-profile.directive';
import {GrantIfDirective} from './grant-if/grant-if.directive';

@NgModule({
  declarations: [
    AppGrantDirective,
    FuseIfOnDomDirective,
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FusePerfectScrollbarDirective,
    AwsSrcDirective,
    AwsProfileDirective,
    GrantIfDirective
  ],
  exports: [
    AppGrantDirective,
    FuseIfOnDomDirective,
    FuseMatSidenavHelperDirective,
    FuseMatSidenavTogglerDirective,
    FusePerfectScrollbarDirective,
    AwsSrcDirective,
    AwsProfileDirective,
    GrantIfDirective
  ]
})
export class DirectiveModule {

}
