import {IOrderState, State} from './state';
import * as OrderActions from './action';

export type Action = OrderActions.All;
export const initState: IOrderState = State() as IOrderState;

export function orderReducer(state: IOrderState = initState, action: Action) {
  switch (action.type) {

    case OrderActions.ORDER_GET_LIST_SUCCESS:
      action = action as OrderActions.OrderGetListSuccess;
      return  state.merge({
        total: action.total,
        list: action.list
      });

    case OrderActions.ORDER_SELECT:
    case OrderActions.ORDER_GET_DETAIL_SUCCESS:
      action = action as OrderActions.OrderSelect;
      return state.merge({
        selected: action.order
      });

    case OrderActions.ORDER_SELECT_CANCEL:
      return state.merge({ selected: null});

    default:
      return state;
  }
}
