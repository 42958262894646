
import {IModel} from './interface';
import {Serializable} from '../helper/serializable';

export class Order extends Serializable implements IModel {
  _id: string;
  user: any = {
    _id: '',
    name: ''
  };
  name: string;
  amount: number;
  state: number;
  payment: any;
  type: number;
  bank: string;
  receiver: any;
  vendor: any;
  orders: any[];
  date: Date = new Date();


  constructor(
    _id?: string,
    user?: any,
    name?: string,
    amount?: number,
    state?: number,
    payment?: any,
    type?: number,
    bank?: string,
    receiver?: any,
    vendor?: any,
    date?: Date
  ) {
    super();
  }

  toObject(): object {
    return {
      _id : this._id,
      user: this.user,
      name: this.name,
      amount: this.amount,
      state: this.state,
      payment: this.payment,
      type: this.type,
      bank: this.bank,
      receiver: this.receiver,
      vendor: this.vendor,
      date: this.date
    };
  }
}
