
import {IModel} from './interface';
import {Serializable} from '../helper/serializable';

export class Product extends Serializable implements IModel {
  _id: string;
  title: string;
  description: string;
  price: number;
  state: number;
  cover: any;
  date: Date = new Date();


  constructor(
    _id?: string,
    title?: any,
    description?: string,
    price?: number,
    state?: number,
    cover?: any,
    date?: Date
  ) {
    super();
    this._id = _id;
    this.title = title;
    this.description = description;
    this.price = price;
    this.state = state;
    this.cover = cover;
    this.date = date;
  }

  toObject(): object {
    return {
      _id : this._id,
      title: this.title,
      description: this.description,
      price: this.price,
      state: this.state,
      cover: this.cover
    };
  }

  customDeserializer(key: string, value: any): any {
    let result = value;
    switch (key) {
      case 'date':
        result = new Date(Date.parse(value));
        break;
    }
    return result;
  }
}
