import {Record, Map, List} from 'immutable';
import {Product} from '../../model/product';

export interface IProductState extends Map<string, any> {
  list: List<Product>;
  total: number;
  selected: Product;

}

export const State = Record({
  list: List<Product>(),
  total: 0,
  selected: null
});
