import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {FuseContentComponent} from './content.component';

@NgModule({
  declarations: [
    FuseContentComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    FuseContentComponent
  ]
})
export class FuseContentModule {
}
