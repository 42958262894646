import {Injectable} from '@angular/core';
import {HttpService} from '../service/aram/http.service';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {IResponse} from '../helper/response';

@Injectable()
export class OrderApiService extends BaseService {
  constructor(http: HttpService) {
    super('orders', http);
  }

  me(offset:number, count:number, id:string): Observable<IResponse<any>> {
    return this.http.get(`${this.controllerName}`, {offset:offset, count: count, user_id: id});
  }
}
