import {userReducer} from './core/redux/user/reducer';
import {articleReducer} from './core/redux/article/reducer';
import {clueReducer} from './core/redux/clue/reducer';
import {faqReducer} from './core/redux/faq/reducer';
import {foundReducer} from './core/redux/found/reducer';
import {orderReducer} from './core/redux/order/reducer';
import {bankReducer} from './core/redux/bank/reducer';
import {inquiryReducer} from './core/redux/inquiry/reducer';
import {productReducer} from './core/redux/product/reducer';

export const AppReducers = {
  article: articleReducer,
  user: userReducer,
  clue: clueReducer,
  faq: faqReducer,
  found: foundReducer,
  order: orderReducer,
  bank : bankReducer,
  inquiry: inquiryReducer,
  product: productReducer
};
