// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API_ENDPOINT: 'http://localhost:3000/',
  API_ENDPOINT: 'https://api.aliascall.com/',
  APP_NAME: 'ALIAS',
  AWS_S3_MEDIA_TEMP_URL: 'https://s3.ap-northeast-2.amazonaws.com/temp.aliascall.com/',
  AWS_S3_MEDIA_URL: 'https://s3.ap-northeast-2.amazonaws.com/media.aliascall.com/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
