import {Action} from '@ngrx/store';
import {Clue} from '../../model/clue';


export const CLUE_GET_LIST = 'CLUE_GET_LIST';
export class ClueGetList implements Action {
  readonly type = CLUE_GET_LIST;
  constructor(public offset: number, public count: number, public id: string, public keyword: string) {
  }
}

export const CLUE_GET_LIST_SUCCESS = 'CLUE_GET_LIST_SUCCESS';
export class ClueGetListSuccess implements Action {
  readonly type = CLUE_GET_LIST_SUCCESS;
  constructor(public total: number, public list: Clue[]) {
  }
}

export const CLUE_DELETE = 'CLUE_DELETE';
export class ClueDelete implements Action {
  readonly type = CLUE_DELETE;
  constructor(public clue: Clue, public reloadAction: ClueGetList) {}
}

export type All =
  ClueGetList |
  ClueGetListSuccess |
  ClueDelete
  ;
