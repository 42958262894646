import {Record, Map, List} from 'immutable';
import {Faq} from '../../model/faq';

export interface IFaqState extends Map<string, any> {
  list: List<Faq>;
  total: number;
  selected: Faq;
}

export const State = Record({
  list: List<Faq>(),
  total: 0,
  selected: null
});
