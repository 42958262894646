import {IClueState, State} from './state';
import * as ClueActions from './action';

export type Action = ClueActions.All;
export const initClueState: IClueState = State() as IClueState;

export function clueReducer(state: IClueState = initClueState, action: Action) {
  switch (action.type) {
    case ClueActions.CLUE_GET_LIST_SUCCESS:
      action = action as ClueActions.ClueGetListSuccess;
      return  state.merge({
        total: action.total,
        list: action.list
      });
    default:
      return state;
  }
}
