import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {locale as navigationEnglish} from './navigation/i18n/en';
import {locale as navigationKorea} from './navigation/i18n/kr';

import {FuseSplashScreenService} from './core/service/fuse/splash-screen.service';
import {FuseTranslationLoaderService} from './core/service/fuse/translation-loader.service';
import {FuseNavigationService} from './core/service/fuse/navigation.service';

@Component({
  selector: 'fuse-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private translate: TranslateService,
    private fuseNavigationService: FuseNavigationService,
    private fuseSplashScreen: FuseSplashScreenService,
    private fuseTranslationLoader: FuseTranslationLoaderService
  ) {
    // Add languages
    this.translate.addLangs(['kr', 'en']);

    // Set the default language
    this.translate.setDefaultLang('kr');

    // Set the navigation translations
    this.fuseTranslationLoader.loadTranslations(navigationKorea, navigationEnglish);

    // Use a language
    this.translate.use('kr');
  }
}
