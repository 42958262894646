import {Injectable} from '@angular/core';
import {IResponse, RESPONSE_CODE} from '../../helper/response';
import {Actions, Effect} from '@ngrx/effects';

import * as InquiryActions from './action';
import * as RouterActions from '../router/router.action';

import {Converter} from '../../helper/converter';
import {List} from 'immutable';
import {Inquiry} from '../../model/inquiry';
import {InquiryApiService} from '../../api/inquiry-api.service';


@Injectable()

export class InquiryEffect {

  @Effect() GetList$ = this.action$
    .ofType(InquiryActions.INQUIRY_GET_LIST)
    .switchMap((action: InquiryActions.InquiryGetList) => {
      return this.api
        .list(action.offset, action.count, {keyword: action.keyword, category: action.category})
        .map((res: IResponse<Inquiry[]>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const inquiries: List<Inquiry> = Converter.jsonToInstance<Inquiry>(Inquiry, res.data.list);
            return new InquiryActions.InquiryGetListSuccess(res.data.total, inquiries);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Get$ = this.action$
    .ofType(InquiryActions.INQUIRY_GET_DETAIL)
    .switchMap((action: InquiryActions.InquiryGetDetail) => {
      return this.api
        .get(action.id)
        .map((res: IResponse<Inquiry>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const inquiry: Inquiry = Converter.jsonToInstance<Inquiry>(Inquiry, res.data);
            return new InquiryActions.InquiryGetDetailSuccess(inquiry);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Delete$ = this.action$
    .ofType(InquiryActions.INQUIRY_DELETE)
    .switchMap((action: InquiryActions.InquiryDelete) => {
      return this.api
        .delete(action.inquiry._id)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return action.reloadAction;
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Modify$ = this.action$
    .ofType(InquiryActions.INQUIRY_MODIFY)
    .switchMap((action: InquiryActions.InquiryModify) => {
      return this.api
        .modify(action.inquiry)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Go({path: ['inquiry/list']});
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  constructor(private action$: Actions, private api: InquiryApiService) {}
}
