export enum RESOURCE_STATE {
  SOURCE = 0,
  RELEASE = 1
}

export class Resource {
  key: string;
  name: string;
  state: RESOURCE_STATE;

  constructor(
    key?: string,
    name?: string,
    state?: RESOURCE_STATE
  ) {
    this.key = key;
    this.name = name;
    this.state = state;
  }
}
