import {NgModule} from '@angular/core';
import {ComponentModule} from './component/component.module';
import {DirectiveModule} from './directive/directive.module';
import {MaterialModule} from './material/material.module';
import {PipeModule} from './pipe/pipe.module';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [
    ComponentModule,
    DirectiveModule,
    MaterialModule,
    PipeModule,
    RouterModule
  ],
  exports: [
    ComponentModule,
    DirectiveModule,
    MaterialModule,
    PipeModule,
    RouterModule,
  ],
  declarations: [
    ],
  providers: []
})
export class SharedModule { }
