import {NgModule} from '@angular/core';
import {AngularMaterial} from './angular.material';

@NgModule({
  imports: [
    AngularMaterial
  ],
  exports: [
    AngularMaterial
  ]
})
export class MaterialModule { }
