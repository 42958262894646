import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {FuseQuickPanelComponent} from './quick-panel.component';

@NgModule({
  declarations: [
    FuseQuickPanelComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    FuseQuickPanelComponent
  ]
})
export class FuseQuickPanelModule {
}
