import {Injectable} from '@angular/core';
import {IResponse, RESPONSE_CODE} from '../../helper/response';
import {Actions, Effect} from '@ngrx/effects';

import * as FaqActions from './action';
import * as RouterActions from '../router/router.action';
import {FaqApiService} from '../../api/faq-api.service';
import {Faq} from '../../model/faq';
import {Converter} from '../../helper/converter';
import {List} from 'immutable';


@Injectable()

export class FaqEffect {

  @Effect() Create$ = this.action$
    .ofType(FaqActions.FAQ_CREATE)
    .switchMap((action: FaqActions.FaqCreate) => {
      return this.api
        .add(action.faq)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Back();
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() GetList$ = this.action$
    .ofType(FaqActions.FAQ_GET_LIST)
    .switchMap((action: FaqActions.FaqGetList) => {
      return this.api
        .list(action.offset, action.count, {keyword: action.keyword, category: action.category})
        .map((res: IResponse<Faq[]>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const faqs: List<Faq> = Converter.jsonToInstance<Faq>(Faq, res.data.list);
            return new FaqActions.FaqGetListSuccess(res.data.total, faqs);
          } else {
            return {type: 'NUL_ACTION'};
          }
        });
    });

  @Effect() Get$ = this.action$
    .ofType(FaqActions.FAQ_GET_DETAIL)
    .switchMap((action: FaqActions.FaqGetDetail) => {
      return this.api
        .get(action.id)
        .map((res: IResponse<Faq>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const faq: Faq = Converter.jsonToInstance<Faq>(Faq, res.data.value);
            return new FaqActions.FaqGetDetailSuccess(faq);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Delete$ = this.action$
    .ofType(FaqActions.FAQ_DELETE)
    .switchMap((action: FaqActions.FaqDelete) => {
      return this.api
        .delete(action.faq._id)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return action.reloadAction;
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Modify$ = this.action$
    .ofType(FaqActions.FAQ_MODIFY)
    .switchMap((action: FaqActions.FaqModify) => {
      return this.api
        .modify(action.faq)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Back();
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  constructor(private action$: Actions, private api: FaqApiService) {}
}
