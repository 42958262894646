import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {IResponse, RESPONSE_CODE} from '../../helper/response';

import * as OrderActions from './action';
import * as RouterActions from '../router/router.action';
import {Converter} from '../../helper/converter';
import {Order} from '../../model/order';
import {OrderApiService} from '../../api/order-api.service';

@Injectable()
export class OrderEffect {

  @Effect() UserGetList$ = this.action$
    .ofType(OrderActions.ORDER_GET_LIST)
    .switchMap((action: OrderActions.OrderGetList) => {
      return this.orderApi
        .list(action.offset, action.count, action.keyword)
        .map((res: IResponse<Order[]>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const orders: Order[] = Converter.jsonToInstance<Order>(Order, res.data.list);
            return new OrderActions.OrderGetListSuccess(res.data.total, orders);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Delete$ = this.action$
    .ofType(OrderActions.ORDER_DELETE)
    .switchMap((action: OrderActions.OrderDelete) => {
      return this.orderApi
        .delete(action.order._id)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return action.reloadAction;
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Modify$ = this.action$
    .ofType(OrderActions.ORDER_MODIFY)
    .switchMap((action: OrderActions.OrderModify) => {
      return this.orderApi
        .modify(action.order)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Go({path: ['order/list']});
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    })

  @Effect() Get$ = this.action$
    .ofType(OrderActions.ORDER_GET_DETAIL)
    .switchMap((action: OrderActions.OrderGetDetail) => {
      return this.orderApi
        .get(action.id)
        .map((res: IResponse<Order>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const order: Order = Converter.jsonToInstance<Order>(Order, res.data);
            return new OrderActions.OrderGetDetailSuccess(order);
          }
        });
    });

  constructor(private action$: Actions, private orderApi: OrderApiService) {
  }
}
