import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';

import {FuseConfirmDialogModule} from './confirm-dialog/confirm-dialog.module';
import {FuseCountdownModule} from './countdown/countdown.module';
import {FuseDemoModule} from './demo/demo.module';
import {FuseHighlightModule} from './highlight/highlight.module';
import {FuseMaterialColorPickerModule} from './material-color-picker/material-color-picker.module';
import {FuseNavigationModule} from './navigation/navigation.module';
import {FuseSearchBarModule} from './search-bar/search-bar.module';
import {FuseShortcutsModule} from './shortcuts/shortcuts.module';
import {FuseSidebarModule} from './sidebar/sidebar.module';
import {FuseThemeOptionsModule} from './theme-options/theme-options.module';
import {FuseWidgetModule} from './widget/widget.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseConfirmDialogModule,
    FuseCountdownModule,
    FuseDemoModule,
    FuseHighlightModule,
    FuseMaterialColorPickerModule,
    FuseNavigationModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    FuseWidgetModule,
  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FuseConfirmDialogModule,
    FuseCountdownModule,
    FuseDemoModule,
    FuseHighlightModule,
    FuseMaterialColorPickerModule,
    FuseNavigationModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
    FuseWidgetModule,
  ]
})
export class ComponentModule {}
