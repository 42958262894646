import {Injectable} from '@angular/core';
import {IResponse, RESPONSE_CODE} from '../../helper/response';
import {Actions, Effect} from '@ngrx/effects';

import * as FoundActions from './action';
import * as RouterActions from '../router/router.action';

import {Converter} from '../../helper/converter';
import {List} from 'immutable';
import {Found} from '../../model/found';
import {FoundApiService} from '../../api/found-api.service';


@Injectable()

export class FoundEffect {

  @Effect() GetList$ = this.action$
    .ofType(FoundActions.FOUND_GET_LIST)
    .switchMap((action: FoundActions.FoundGetList) => {
      return this.api
        .list(action.offset, action.count, action.keyword)
        .map((res: IResponse<Found[]>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const founds: List<Found> = Converter.jsonToInstance<Found>(Found, res.data.list);
            return new FoundActions.FoundGetListSuccess(res.data.total, founds);
          } else {
            return {type: 'NUL_ACTION'};
          }
        });
    });

  @Effect() Get$ = this.action$
    .ofType(FoundActions.FOUND_GET_DETAIL)
    .switchMap((action: FoundActions.FoundGetDetail) => {
      return this.api
        .get(action.id)
        .map((res: IResponse<Found>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const found: Found = Converter.jsonToInstance<Found>(Found, res.data.value);
            return new FoundActions.FoundGetDetailSuccess(found);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Delete$ = this.action$
    .ofType(FoundActions.FOUND_DELETE)
    .switchMap((action: FoundActions.FoundDelete) => {
      return this.api
        .delete(action.found._id)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return action.reloadAction;
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });
  //
  // @Effect() Modify$ = this.action$
  //   .ofType(FaqActions.FAQ_MODIFY)
  //   .switchMap((action: FaqActions.FaqModify) => {
  //     return this.api
  //       .modify(action.faq)
  //       .map((res: IResponse<any>) => {
  //         if (res.code === RESPONSE_CODE.SUCCESS) {
  //           return new RouterActions.Go({path: ['faq/list']});
  //         } else {
  //           return {type: 'NULL_ACTION'};
  //         }
  //       });
  //   })

  constructor(private action$: Actions, private api: FoundApiService) {}
}
