import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import 'rxjs/add/operator/map';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/finally';
import 'rxjs/add/observable/of';
import {ApiModule} from './api/api.module';
import {DialogModule} from './dialog/dialog.module';
import {ServiceModule} from './service/service.module';
import {FUSE_CONFIG} from './service/fuse/config.service';
import {HttpClientModule} from '@angular/common/http';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApiModule,
    DialogModule,
    ServiceModule,
    HttpClientModule
  ],
  declarations: [],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApiModule,
    DialogModule,
    ServiceModule
  ],
  providers: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('FuseModule is already loaded. Import it in the AppModule only!');
    }
  }

  static forRoot(config): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: FUSE_CONFIG,
          useValue: config
        }
      ]
    };
  }
}
