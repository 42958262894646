import {Record, Map, List} from 'immutable';
import {Order} from '../../model/order';

export interface IOrderState extends Map<string, any> {
  list: List<Order>;
  total: number;
  selected: Order;

}

export const State = Record({
  list: List<Order>(),
  total: 0,
  selected: null
});
