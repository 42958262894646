import {Record, Map, List} from 'immutable';
import {Clue} from '../../model/clue';

export interface IClueState extends Map<string, any> {
  list: List<Clue>;
  total: number;
}

export const State = Record({
  list: List<Clue>(),
  total: 0,
});
