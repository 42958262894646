import {Directive, Input, OnInit, HostListener, OnDestroy, HostBinding} from '@angular/core';
import {MatSidenav} from '@angular/material';
import {ObservableMedia} from '@angular/flex-layout';
import {Subscription} from 'rxjs';

import {FuseMatchMediaService} from 'src/app/core/service/fuse/match-media.service';
import {FuseMatSidenavHelperService} from '../../../core/service/fuse/mat-sidenav.service';

@Directive({
  selector: '[fuseMatSidenavHelper]'
})
export class FuseMatSidenavHelperDirective implements OnInit, OnDestroy {
  matchMediaSubscription: Subscription;
  @HostBinding('class.mat-is-locked-open') isLockedOpen = true;
  @Input('fuseMatSidenavHelper') id: string;
  @Input('mat-is-locked-open') matIsLockedOpenBreakpoint: string;

  constructor(
    private fuseMatSidenavService: FuseMatSidenavHelperService,
    private fuseMatchMedia: FuseMatchMediaService,
    private observableMedia: ObservableMedia,
    private matSidenav: MatSidenav
  ) {
  }

  ngOnInit() {
    this.fuseMatSidenavService.setSidenav(this.id, this.matSidenav);

    if (this.observableMedia.isActive(this.matIsLockedOpenBreakpoint)) {
      this.isLockedOpen = true;
      this.matSidenav.mode = 'side';
      this.matSidenav.toggle(true);
    } else {
      this.isLockedOpen = false;
      this.matSidenav.mode = 'over';
      this.matSidenav.toggle(false);
    }

    this.matchMediaSubscription = this.fuseMatchMedia.onMediaChange.subscribe(() => {
      if (this.observableMedia.isActive(this.matIsLockedOpenBreakpoint)) {
        this.isLockedOpen = true;
        this.matSidenav.mode = 'side';
        this.matSidenav.toggle(true);
      } else {
        this.isLockedOpen = false;
        this.matSidenav.mode = 'over';
        this.matSidenav.toggle(false);
      }
    });
  }

  ngOnDestroy() {
    this.matchMediaSubscription.unsubscribe();
  }
}
