import {IModel} from './interface';
import {Serializable} from '../helper/serializable';

export enum UserLevel {
  NORMAL = 1,
  MANAGER = 2,
  SUPER = 9
}

export class User extends Serializable implements IModel {
  _id: string;
  pass: string = undefined;
  name: string = undefined;
  phone: string;
  level: number;
  state: number;
  date: Date;

  constructor(
    _id?: string,
    pass?: string,
    name?: string,
    phone?: string,
    level?: number,
  ) {
    super();
  }

  toObject(): object {
    return {
      _id: this._id,
      pass: this.pass,
      name: this.name,
      phone: this.phone,
      level: this.level
    };
  }
}

