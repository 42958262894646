import {Directive, HostListener, Input} from '@angular/core';
import {FuseMatSidenavHelperService} from '../../../core/service/fuse/mat-sidenav.service';

@Directive({
  selector: '[fuseMatSidenavToggler]'
})
export class FuseMatSidenavTogglerDirective {

  @Input('fuseMatSidenavToggler') id;

  constructor(private fuseMatSidenavService: FuseMatSidenavHelperService) {
  }

  @HostListener('click')
  onClick() {
    this.fuseMatSidenavService.getSidenav(this.id).toggle();
  }
}
