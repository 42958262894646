import {NgModule} from '@angular/core';
import {AramPipeModule} from './aram/aram-pipe.module';
import {FusePipesModule} from './fuse/fuse-pipe.module';
import {CommonPipeModule} from './common/common-pipe.module';

@NgModule({
  imports: [
    AramPipeModule,
    FusePipesModule,
    CommonPipeModule
  ],
  exports: [
    AramPipeModule,
    FusePipesModule,
    CommonPipeModule
  ]
})
export class PipeModule {}
