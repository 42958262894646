import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {List} from 'immutable';

import {BankApiService} from '../../api/bank-api.service';
import {Article} from '../../model/article';
import {Converter} from '../../helper/converter';
import {IResponse, RESPONSE_CODE} from '../../helper/response';
import {Bank} from '../../model/bank';

import * as BankActions from './action';
import * as RouterActions from '../router/router.action';


@Injectable()
export class BankEffect {

  @Effect() Create$ = this.actions$
    .ofType(BankActions.BANK_CREATE)
    .switchMap((action: BankActions.BankCreate) => {
      return this.api
        .add(action.bank)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Go({path: ['/bank/list']});
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Modify$ = this.actions$
    .ofType(BankActions.BANK_MODIFY)
    .switchMap((action: BankActions.BankModify) => {
      return this.api
        .modify(action.bank)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Go({path: ['bank/list']});
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() GetList$ = this.actions$
    .ofType(BankActions.BANK_GET_LIST)
    .switchMap((action: BankActions.BankGetList) => {
      return this.api
        .list(action.offset, action.count)
        .map((res: IResponse<Article[]>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const banks: List<Bank> = Converter.jsonToInstance<Bank>(Bank, res.data.list);
            return new BankActions.BankGetListSuccess(res.data.total, banks);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Delete$ = this.actions$
    .ofType(BankActions.BANK_DELETE)
    .switchMap((action: BankActions.BankDelete) => {
      return this.api
        .delete(action.bank._id)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return action.reloadAction;
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() Get$ = this.actions$
    .ofType(BankActions.BANK_GET_DETAIL)
    .switchMap((action: BankActions.BankGetDetail) => {
      return this.api
        .get(action.id)
        .map((res: IResponse<Bank>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const bank: Bank = Converter.jsonToInstance<Bank>(Bank, res.data.value);
            return new BankActions.BankGetDetailSuccess(bank);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  constructor(private actions$: Actions, private api: BankApiService) { }
}
