import {Action} from '@ngrx/store';
import {Product} from '../../model/product';

export const PRODUCT_GET_LIST = 'PRODUCT_GET_LIST';
export class ProductGetList implements Action {
  readonly type = PRODUCT_GET_LIST;
  constructor(public offset: number, public count: number, public keyword?: string) { }
}

export const PRODUCT_GET_LIST_SUCCESS = 'PRODUCT_GET_LIST_SUCCESS';
export class ProductGetListSuccess implements Action {
  readonly type = PRODUCT_GET_LIST_SUCCESS;
  constructor(public total: number, public list: Product[]) { }
}

export const PRODUCT_SELECT = 'PRODUCT_SELECT';
export class ProductSelect implements Action {
  readonly type = PRODUCT_SELECT;
  constructor(public product: Product) { }
}

export const PRODUCT_CLEAR = 'PRODUCT_CLEAR';
export class ProductClear implements Action {
  readonly type = PRODUCT_CLEAR;
  constructor() {}
}

export type All =
  ProductGetList |
  ProductGetListSuccess |
  ProductSelect |
  ProductClear
  ;
