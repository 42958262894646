import {Injectable} from '@angular/core';
import {HttpService} from '../service/aram/http.service';
import {BaseService} from './base.service';
import {Observable} from 'rxjs/Observable';
import {IResponse} from '../helper/response';

@Injectable()
export class FoundApiService extends BaseService {
  constructor(http: HttpService) {
    super('founds', http);
  }

  report(id: string, state: number, reason: string, contact: string): Observable<IResponse<any>> {
    return this.http.put<IResponse<any>>(`${this.controllerName}/${id}/report`, {state: state, reason: reason, contact: contact});
  }
}
