import {Component} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {navigation} from 'src/app/navigation/navigation';
import {FuseConfigService} from '../../core/service/fuse/config.service';
import {FuseSidebarService} from '../../shared/component/sidebar/sidebar.service';
import {MembershipApiService} from '../../core/api/membership-api.service';
import {RESPONSE_CODE} from '../../core/helper/response';
import {Store} from '@ngrx/store';
import {AppStore} from '../../app-store.interface';
import * as RouterActions from '../../core/redux/router/router.action';
import {SessionService} from '../../core/service/aram/session.service';

@Component({
  selector: 'fuse-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class FuseToolbarComponent {
  userStatusOptions: any[];
  languages: any;
  selectedLanguage: any;
  showLoadingBar: boolean;
  horizontalNav: boolean;
  noNav: boolean;
  navigation: any;

  constructor(
    private router: Router,
    private fuseConfig: FuseConfigService,
    private sidebarService: FuseSidebarService,
    private translate: TranslateService,
    private membership: MembershipApiService,
    private store: Store<AppStore>,
    private session: SessionService
  ) {
    this.userStatusOptions = [
      {
        'title': 'Online',
        'icon': 'icon-checkbox-marked-circle',
        'color': '#4CAF50'
      },
      {
        'title': 'Away',
        'icon': 'icon-clock',
        'color': '#FFC107'
      },
      {
        'title': 'Do not Disturb',
        'icon': 'icon-minus-circle',
        'color': '#F44336'
      },
      {
        'title': 'Invisible',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#BDBDBD'
      },
      {
        'title': 'Offline',
        'icon': 'icon-checkbox-blank-circle-outline',
        'color': '#616161'
      }
    ];

    this.languages = [
      {
        'id': 'kr',
        'title': '한국어',
        'flag': 'kr'
      },
      {
        'id': 'en',
        'title': 'English',
        'flag': 'us'
      }
    ];

    this.selectedLanguage = this.languages[0];

    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.showLoadingBar = true;
        }
        if (event instanceof NavigationEnd) {
          this.showLoadingBar = false;
        }
      });

    this.fuseConfig.onConfigChanged.subscribe((settings) => {
      this.horizontalNav = settings.layout.navigation === 'top';
      this.noNav = settings.layout.navigation === 'none';
    });

    this.navigation = navigation;
  }

  toggleSidebarOpened(key) {
    this.sidebarService.getSidebar(key).toggleOpen();
  }

  search(value) {
    // Do your search here...
  }

  setLanguage(lang) {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this.translate.use(lang.id);
  }

  async logout(): Promise<any> {
    this.membership.logout().subscribe(
      rs => {
        if (rs.code === RESPONSE_CODE.SUCCESS) {
          this.session.destory();
          this.store.dispatch(new RouterActions.Go({path: ['/login']}));
        }
      }
    );
    // console.log('ddd');
    // const rs = await this.membership.logout();
    //
    // // if (rs.code === RESPONSE_CODE.SUCCESS) {
    // //
    // // }
    // console.log(rs);
    // this.membership.logout().subscribe(
    //   rs => {
    //     console.log(rs);
    //   }
    // );
  }
}
