import {Action} from '@ngrx/store';
import {Article} from '../../model/article';
import {List} from 'immutable';


export const ARTICLE_GET_LIST = 'ARTICLE_GET_LIST';
export class ArticleGetList implements Action {
  readonly type = ARTICLE_GET_LIST;
  constructor(public category: string, public offset: number, public count: number, public keyword?: string) {
  }
}

export const ARTICLE_GET_LIST_SUCCESS = 'ARTICLE_GET_LIST_SUCCESS';
export class ArticleGetListSuccess implements Action {
  readonly type = ARTICLE_GET_LIST_SUCCESS;
  constructor(public total: number, public list: List<Article>) {
  }
}

export const ARTICLE_GET_DETAIL = 'ARTICLE_GET_DETAIL';
export class ArticleGetDetail implements Action {
  readonly type = ARTICLE_GET_DETAIL;
  constructor(public id: string) {
  }
}

export const ARTICLE_GET_DETAIL_SUCCESS = 'ARTICLE_GET_DETAIL_SUCCESS';
export class ArticleGetDetailSuccess implements Action {
  readonly type = ARTICLE_GET_DETAIL_SUCCESS;
  constructor(public article: Article) {
  }
}

export const ARTICLE_SELECT = 'ARTICLE_SELECT';
export class ArticleSelect implements Action {
  readonly type = ARTICLE_SELECT;
  constructor(public article: Article) { }
}

export const ARTICLE_SELECT_CANCEL = 'ARTICLE_SELECT_CANCEL';
export class ArticleSelectCancel implements Action {
  readonly type = ARTICLE_SELECT_CANCEL;
  constructor() { }
}

export const ARTICLE_CREATE = 'ARTICLE_CREATE';
export class ArticleCreate implements Action {
  readonly type = ARTICLE_CREATE;
  constructor(public article: Article) {
  }
}

export const ARTICLE_MODIFY = 'ARTICLE_MODIFY';
export class ArticleModify implements Action {
  readonly type = ARTICLE_MODIFY;
  constructor(public article: Article) {}
}

export const ARTICLE_DELETE = 'ARTICLE_DELETE';
export class ArticleDelete implements Action {
  readonly type = ARTICLE_DELETE;
  constructor(public article: Article, public reloadAction: ArticleGetList) {
  }
}

export type All =
  ArticleGetList |
  ArticleGetListSuccess |
  ArticleGetDetail |
  ArticleGetDetailSuccess|
  ArticleSelect |
  ArticleSelectCancel |
  ArticleCreate |
  ArticleModify |
  ArticleDelete;
