import {Record, Map, List} from 'immutable';
import {Found} from '../../model/found';

export interface IFoundState extends Map<string, any> {
  list: List<Found>;
  total: number;
  selected: Found;
}

export const State = Record({
  list: List<Found>(),
  total: 0,
  selected: null
});
