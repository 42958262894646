import {Record, Map, List} from 'immutable';
import {Bank} from '../../model/bank';

export interface IBankState extends Map<string, any> {
  list: List<Bank>;
  total: number;
  selected: Bank;
}

export const State = Record({
  list: List<Bank>(),
  total: 0,
  selected: null
});
