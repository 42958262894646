export const locale = {
  lang: 'kr',
  data: {
    'NAV': {
      'MENU'        : '메뉴',
      'DASHBOARDS'  : '대시보드',
      'NOTICE'      : '공지사항',
      'BANNER'      : '배너',
      'USER'        : '사용자 관리',
      'INQUIRY'     : '1:1문의',
      'FAQS'        : '질의응답',
      'USAGE'       : '사용법',
      'FOUND'       : '분실물 관리',
      'CLUE'        : '증거품 관리',
      'ORDER'       : '결제내역',
      'PRODUCT'     : '상품관리',
      'BANK'        : '입금계좌관리'
    }
  }
};
