import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {TranslateModule} from '@ngx-translate/core';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppReducers} from './app.reducer';
import {AppEffects} from './app.effect';
import {CoreModule} from './core/core.module';
import {fuseConfig} from './fuse-config';
import {FuseMainModule} from './main/main.module';
import {ResponseInterceptor} from './core/interceptor/response.interceptor';
import {CustomSerializer} from './core/redux/router/router.serializer';
import {SharedModule} from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    AppRoutingModule,
    TranslateModule.forRoot(),
    StoreModule.forRoot(AppReducers),
    StoreDevtoolsModule.instrument({maxAge: 5}),
    StoreRouterConnectingModule,
    EffectsModule.forRoot(AppEffects),
    CoreModule.forRoot(fuseConfig),
    SharedModule,
    FuseMainModule
  ],
  providers: [
    {provide: RouterStateSerializer, useClass: CustomSerializer},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
