import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Resource, RESOURCE_STATE} from '../../model/resource';

@Injectable()
export class SummernoteService {

  private instance: any = {};
  private basicConfig: any = {
    tabsize: 2,
    height: 240,
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style0', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      ['view', ['fullscreen', 'codeview']],
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Nanum Barun Gothic', 'Times', 'Nanum Myeongjo'],
    fontNamesIgnoreCheck: ['Nanum Barun Gothic', 'Nanum Myeongjo'],
    disableResizeEditor: true
  };

  init(id: string, config: any): any {
    const customConfig = Object.assign({}, this.basicConfig);
    Object.keys(config).forEach(key => customConfig[key] = config[key]);
    this.instance[id] = global['jQuery'](`#${id}`).summernote(customConfig);
  }

  insertText(id: string, text: string): void {
    this.instance[id].summernote('insertText', text);
  }

  insertImage(id: string, src: Resource, folder): void {
    const url = (src.state === RESOURCE_STATE.SOURCE) ? `${environment.AWS_S3_MEDIA_TEMP_URL}${src.key}` : `${environment.AWS_S3_MEDIA_URL}${folder}/${src.key}`;
    const imgElement = document.createElement('img');
    imgElement.setAttribute('src', url);
    imgElement.setAttribute('class', 'image');
    imgElement.setAttribute('id', src.key);
    imgElement.setAttribute('state', src.state.toString());
    this.instance[id].summernote('insertNode', imgElement);
  }


  isEmpty(id: string): boolean {
    return this.instance[id].summernote('isEmpty');
  }

  parse(id: string, folder: string): {code: string, images: Resource[]} {
    const images: Resource[] = [];
    const code: string = this.instance[id].summernote('code');
    const wrapper = document.createElement('div');
    wrapper.innerHTML = code;
    const imageElements = wrapper.querySelectorAll('.image');
    for (let i = 0; i < imageElements.length; i++) {
      images.push(new Resource(imageElements[i].getAttribute('id'), undefined, Number(imageElements[i].getAttribute('state'))));
      imageElements[i].setAttribute('src', `${environment.AWS_S3_MEDIA_URL}${imageElements[i].getAttribute('id')}`);
      imageElements[i].setAttribute('state', RESOURCE_STATE.RELEASE.toString());
    }
    return {code: wrapper.innerHTML, images: images};
  }

  setCode(id: string, html: String): void {
    this.instance[id].summernote('code', html);
  }
}

