
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IResponse} from '../helper/response';
import {HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {HttpService} from '../service/aram/http.service';
import {BaseService} from './base.service';

@Injectable()
export class AwsApiService extends BaseService {
  constructor(http: HttpService) {
    super('aws', http);
  }

  getDownloadUrl(file, type): Observable<IResponse<any>> {
    const params = {
      key: file.key,
      name: file.name,
      type: type
    };
    return this.http.get<IResponse<any>>(`${this.controllerName}/downloadUrl`, params);
  }

  getUploadUrl(): Observable<IResponse<any>> {
    const params = {};
    return this.http.get<IResponse<any>>(`${this.controllerName}/uploadUrl`, params);
  }

  upload(url: string, data: File | any) {
    const headers = new HttpHeaders({
      'Content-Type': ''
    });
    const options = {
      headers: headers,
      withCredentials: true,
      reportProgress: true
    };
    const httpRequest: HttpRequest<any> = new HttpRequest<any>('PUT', url, data, options);
    return this.http.getHttp().request<any>(httpRequest);
  }

  uploadUrlData(url: string, data: any) {
    const headers = new HttpHeaders({
      'Content-Encoding': 'base64',
      'Content-Type': 'image/jpeg'
    });
    const options = {
      headers: headers,
      withCredentials: true,
      reportProgress: true
    };
    const httpRequest: HttpRequest<any> = new HttpRequest<any>('PUT', url, data, options);
    return this.http.getHttp().request<any>(httpRequest).pipe(
      map((res: HttpResponse<any>) => res.body));
  }
}
