import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {IResponse, RESPONSE_CODE} from '../../helper/response';

import * as UserActions from './action';
import * as RouterActions from '../router/router.action';
import {Converter} from '../../helper/converter';
import {UserApiService} from '../../api/user-api.service';
import {User} from '../../model/user';

@Injectable()
export class UserEffect {

  @Effect() UserCreate$ = this.action$
    .ofType(UserActions.USER_CREATE)
    .switchMap((action: UserActions.UserCreate) => {
      return this.userApi
        .add(action.user)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Go({path: ['/user/list']});
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() UserGetList$ = this.action$
    .ofType(UserActions.USER_GET_LIST)
    .switchMap((action: UserActions.UserGetList) => {
      return this.userApi
        .list(action.offset, action.count, action.keyword)
        .map((res: IResponse<User[]>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const users: User[] = Converter.jsonToInstance<User>(User, res.data.list);
            return new UserActions.UserGetListSuccess(res.data.total, users);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() UserModify$ = this.action$
    .ofType(UserActions.USER_MODIFY)
    .switchMap((action: UserActions.UserModify) => {
      return this.userApi
        .modify(action.user)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            return new RouterActions.Back();
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  @Effect() UserGetDetail$ = this.action$
    .ofType(UserActions.USER_GET_DETAIL)
    .switchMap((action: UserActions.UserGetDetail) => {
      return this.userApi
        .detail(action.userId)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const user: User = Converter.jsonToInstance<User>(User, res.data);
            return new UserActions.UserGetDetailSuccess(user);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    })


  constructor(private action$: Actions, private userApi: UserApiService) {
  }
}
