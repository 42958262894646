import {UserEffect} from './core/redux/user/effect';
import {ArticleEffect} from './core/redux/article/effect';
import {MembershipEffect} from './core/redux/membership/membership.effect';
import {RouterEffect} from './core/redux/router/router.effect';
import {ClueEffect} from './core/redux/clue/effect';
import {FaqEffect} from './core/redux/faq/effect';
import {FoundEffect} from './core/redux/found/effect';
import {OrderEffect} from './core/redux/order/effect';
import {BankEffect} from './core/redux/bank/effect';
import {InquiryEffect} from './core/redux/inquiry/effect';
import {PaymentEffect} from './core/redux/payment/effect';
import {ProductEffect} from './core/redux/product/effect';

export const AppEffects = [
  RouterEffect,
  UserEffect,
  MembershipEffect,
  ArticleEffect,
  ClueEffect,
  FaqEffect,
  FoundEffect,
  OrderEffect,
  BankEffect,
  InquiryEffect,
  PaymentEffect,
  ProductEffect
];
