import {Injectable} from '@angular/core';
import {HttpService} from '../service/aram/http.service';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {IResponse} from '../helper/response';

@Injectable()
export class UserApiService extends BaseService {
  constructor(http: HttpService) {
    super('users', http);
  }

  detail(id: string): Observable<IResponse<any>> {
    return this.http.get(`${this.controllerName}/${id}`, {});
  }

  delete(user_id: string): Observable<IResponse<any>> {
    return this.http.delete(`${this.controllerName}/${user_id}`, {});
  }
}
