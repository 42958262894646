import {Action} from '@ngrx/store';

export const PAYMENT_CANCEL = 'PAYMENT_CANCEL';
export class PaymentCancel implements Action {
  readonly type = PAYMENT_CANCEL;
  constructor(public id: string) {}
}



export type All =
  PaymentCancel
  ;
