import {Serializable} from '../helper/serializable';
import {IModel} from './interface';

export class Bank extends Serializable implements IModel {

  _id: string;
  name: string;
  account: string;
  holder: string;

  constructor(
    _id?: string,
    name?: string,
    account?: string,
    holder?: string,
  ) {
    super();
    this._id = _id;
    this.name = name;
    this.account = account;
    this.holder = holder;
  }

  toObject(): object {
    return {
      _id: this._id,
      name: this.name,
      account: this.account,
      holder: this.holder
    };
  }
}
