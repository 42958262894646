import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {FuseToolbarComponent} from './toolbar.component';
import {FuseSearchBarModule} from '../../shared/component/search-bar/search-bar.module';
import {FuseShortcutsModule} from '../../shared/component/shortcuts/shortcuts.module';

@NgModule({
  declarations: [
    FuseToolbarComponent
  ],
  imports: [
    SharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule
  ],
  exports: [
    FuseToolbarComponent
  ]
})
export class FuseToolbarModule {
}
