export const locale = {
  lang: 'en',
  data: {
    'NAV': {
      'MENU'        : 'Menu',
      'DASHBOARDS'  : 'Dashboards',
      'NOTICE'      : 'Notices',
      'BANNER'      : 'Banner',
      'USER'        : 'Users',
      'VENDOR'      : 'Vendors',
      'INQUIRY'     : 'Inquiry',
      'FAQS'        : 'Faqs',
      'USAGE'       : 'Usage',
      'FOUND'       : 'Found',
      'CLUE'        : 'Clue',
      'ORDER'       : 'Order',
      'PRODUCT'     : 'Product',
      'BANK'        : 'BANK'
    }
  }
};
