import {Injectable} from '@angular/core';
import {HttpService} from '../service/aram/http.service';
import {BaseService} from './base.service';

@Injectable()
export class ProductApiService extends BaseService {
  constructor(http: HttpService) {
    super('products', http);
  }
}
