import {NgModule} from '@angular/core';

import {FuseConfigService} from './config.service';
import {FuseCopierService} from './copier.service';
import {FuseMatchMediaService} from './match-media.service';
import {FuseSplashScreenService} from './splash-screen.service';
import {FuseTranslationLoaderService} from './translation-loader.service';
import {FuseNavigationService} from './navigation.service';
import {FuseMatSidenavHelperService} from './mat-sidenav.service';

@NgModule({
  providers: [
    FuseConfigService,
    FuseCopierService,
    FuseMatchMediaService,
    FuseSplashScreenService,
    FuseTranslationLoaderService,
    FuseNavigationService,
    FuseMatSidenavHelperService
  ]
})
export class FuseServiceModule { }

