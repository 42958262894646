import {Pipe, PipeTransform} from '@angular/core';
/**
 * oh
 * 2018. 02. 21.
 * */
@Pipe({
  name: 'userStateConvert'
})
export class UserStateConvert implements PipeTransform {

  transform(value: number): string {
    let state: string;
    switch (value) {
      case -1 :
        state = '탈퇴';
        break;
      case 1 :
        state = '일반';
        break;
      case 9 :
        state = '관리자';
        break;
      default:
        break;
    }
    return state;
  }
}
