import {Injectable} from '@angular/core';
import {HttpService} from '../service/aram/http.service';
import {BaseService} from './base.service';
import {Observable} from 'rxjs/Rx';
import {IResponse} from '../helper/response';

@Injectable()
export class PaymentApiService extends BaseService {
  constructor(http: HttpService) {
    super('payment', http);
  }

  cancel(id: string): Observable<IResponse<any>> {
    return this.http.delete(`${this.controllerName}/inicis/${id}`, {msg: '카드 결제를 취소하였습니다.'});
  }
}
