import {Pipe, PipeTransform} from '@angular/core';
/**
 * oh
 * 2018. 02. 21.
 * */
@Pipe({
  name: 'selectTypeConvert'
})
export class SelectTypeConvert implements PipeTransform {

  transform(value: number): string {
    let type: string;
    switch (value) {
      case 1 :
        type = '카드결제';
        break;
      case 2 :
        type = '무통장입금';
        break;
      default:
        break;
    }
    return type;
  }
}
