import {Serializable} from '../helper/serializable';
import {IModel} from './interface';

export class Clue extends Serializable implements IModel {
  _id: string;
  _u: string;
  unique: string;
  title: string;
  contents: string;
  position: string;
  keyword: string;
  state: string;
  imgs: any[];
  call: boolean;
  msg: boolean;
  lost: boolean;
  lost_title: string;
  lost_position: string;
  lost_content: string;
  lost_date: string;
  writer: any;
  report: any;
  date: Date;
  address: string;
  car: boolean;

  constructor(
    _id?: string,
    _u?: string,
    unique?: string,
    title?: string,
    contents?: string,
    keyword?: string,
    state?: string,
    imgs?: any[],
    call?: boolean,
    lost?: boolean,
    date?: Date,
    address?: string,
  ) {
    super();
  }

  toObject(): object {
    return {
      _id: this._id,
      _u: this._u,
      unique: this.unique,
      title: this.title,
      contents: this.contents,
      keyword: this.keyword,
      state: this. state,
      imgs: this.imgs,
      call: this.call,
      lost: this.lost,
      date: this.date,
      address: this.address,
      car: this.car
    };
  }
}
