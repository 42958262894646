import {Action} from '@ngrx/store';
import {List} from 'immutable';
import {Faq} from '../../model/faq';


export const FAQ_GET_LIST = 'FAQ_GET_LIST';
export class FaqGetList implements Action {
  readonly type = FAQ_GET_LIST;
  constructor(public offset: number, public count: number, public keyword?: string, public category?: number) {
  }
}

export const FAQ_GET_LIST_SUCCESS = 'FAQ_GET_LIST_SUCCESS';
export class FaqGetListSuccess implements Action {
  readonly type = FAQ_GET_LIST_SUCCESS;
  constructor(public total: number, public list: List<Faq>) {
  }
}

export const FAQ_GET_DETAIL = 'FAQ_GET_DETAIL';
export class FaqGetDetail implements Action {
  readonly type = FAQ_GET_DETAIL;
  constructor(public id: string) {}
}

export const FAQ_GET_DETAIL_SUCCESS = 'FAQ_GET_DETAIL_SUCCESS';
export class FaqGetDetailSuccess implements Action {
  readonly type = FAQ_GET_DETAIL_SUCCESS;
  constructor(public faq: Faq) {
  }
}

export const FAQ_SELECT = 'FAQ_SELECT';
export class FaqSelect implements Action {
  readonly type = FAQ_SELECT;
  constructor(public faq: Faq) { }
}

export const FAQ_SELECT_CANCEL = 'FAQ_SELECT_CANCEL';
export class FaqSelectCancel implements Action {
  readonly type = FAQ_SELECT_CANCEL;
  constructor() { }
}

export const FAQ_CREATE = 'FAQ_CREATE';
export class FaqCreate implements Action {
  readonly type = FAQ_CREATE;
  constructor(public faq: Faq) {
  }
}

export const FAQ_MODIFY = 'FAQ_MODIFY';
export class FaqModify implements Action {
  readonly type = FAQ_MODIFY;
  constructor(public faq: Faq) {}
}

export const FAQ_DELETE = 'FAQ_DELETE';
export class FaqDelete implements Action {
  readonly type = FAQ_DELETE;
  constructor(public faq: Faq, public reloadAction: FaqGetList) {
  }
}

export type All =
  FaqGetList |
  FaqGetListSuccess |
  FaqGetDetail |
  FaqGetDetailSuccess|
  FaqSelect |
  FaqSelectCancel |
  FaqCreate |
  FaqModify |
  FaqDelete;
