import {Record, Map, List} from 'immutable';
import {User} from '../../model/user';

export interface IUserState extends Map<string, any> {
  list: List<User>;
  total: number;
  selected: User;
}

export const State = Record({
  list: List<User>(),
  total: 0,
  selected: null
});
