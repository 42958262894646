import {Action} from '@ngrx/store';
import {List} from 'immutable';
import {Faq} from '../../model/faq';
import {Inquiry} from "../../model/inquiry";


export const INQUIRY_GET_LIST = 'INQUIRY_GET_LIST';
export class InquiryGetList implements Action {
  readonly type = INQUIRY_GET_LIST;
  constructor(public offset: number, public count: number, public keyword?: string, public category?: string) {
  }
}

export const INQUIRY_GET_LIST_SUCCESS = 'INQUIRY_GET_LIST_SUCCESS';
export class InquiryGetListSuccess implements Action {
  readonly type = INQUIRY_GET_LIST_SUCCESS;
  constructor(public total: number, public list: List<Inquiry>) {
  }
}

export const INQUIRY_GET_DETAIL = 'INQUIRY_GET_DETAIL';
export class InquiryGetDetail implements Action {
  readonly type = INQUIRY_GET_DETAIL;
  constructor(public id: string) {}
}

export const INQUIRY_GET_DETAIL_SUCCESS = 'INQUIRY_GET_DETAIL_SUCCESS';
export class InquiryGetDetailSuccess implements Action {
  readonly type = INQUIRY_GET_DETAIL_SUCCESS;
  constructor(public inquiry: Inquiry) {
  }
}

export const INQUIRY_SELECT = 'INQUIRY_SELECT';
export class InquirySelect implements Action {
  readonly type = INQUIRY_SELECT;
  constructor(public inquiry: Inquiry) { }
}

export const INQUIRY_SELECT_CANCEL = 'INQUIRY_SELECT_CANCEL';
export class InquirySelectCancel implements Action {
  readonly type = INQUIRY_SELECT_CANCEL;
  constructor() { }
}


export const INQUIRY_MODIFY = 'INQUIRY_MODIFY';
export class InquiryModify implements Action {
  readonly type = INQUIRY_MODIFY;
  constructor(public inquiry: Inquiry) {}
}

export const INQUIRY_DELETE = 'INQUIRY_DELETE';
export class InquiryDelete implements Action {
  readonly type = INQUIRY_DELETE;
  constructor(public inquiry: Inquiry, public reloadAction: InquiryGetList) {
  }
}

export type All =
  InquiryGetList |
  InquiryGetListSuccess |
  InquiryGetDetail |
  InquiryGetDetailSuccess|
  InquirySelect |
  InquirySelectCancel |
  InquiryModify |
  InquiryDelete;
