import {Action} from '@ngrx/store';
import {List} from 'immutable';
import {Bank} from '../../model/bank';


export const BANK_GET_LIST = 'BANK_GET_LIST';
export class BankGetList implements Action {
  readonly type = BANK_GET_LIST;
  constructor(public offset: number, public count: number) {
  }
}

export const BANK_GET_LIST_SUCCESS = 'BANK_GET_LIST_SUCCESS';
export class BankGetListSuccess implements Action {
  readonly type = BANK_GET_LIST_SUCCESS;
  constructor(public total: number, public list: List<Bank>) {
  }
}

export const BANK_GET_DETAIL = 'BANK_GET_DETAIL';
export class BankGetDetail implements Action {
  readonly type = BANK_GET_DETAIL;
  constructor(public id: string) {
  }
}

export const BANK_GET_DETAIL_SUCCESS = 'BANK_GET_DETAIL_SUCCESS';
export class BankGetDetailSuccess implements Action {
  readonly type = BANK_GET_DETAIL_SUCCESS;
  constructor(public bank: Bank) {
  }
}

export const BANK_SELECT = 'BANK_SELECT';
export class BankSelect implements Action {
  readonly type = BANK_SELECT;
  constructor(public bank: Bank) { }
}

export const BANK_SELECT_CANCEL = 'BANK_SELECT_CANCEL';
export class BankSelectCancel implements Action {
  readonly type = BANK_SELECT_CANCEL;
  constructor() { }
}

export const BANK_CREATE = 'BANK_CREATE';
export class BankCreate implements Action {
  readonly type = BANK_CREATE;
  constructor(public bank: Bank) {
  }
}

export const BANK_MODIFY = 'BANK_MODIFY';
export class BankModify implements Action {
  readonly type = BANK_MODIFY;
  constructor(public bank: Bank) {}
}

export const BANK_DELETE = 'BANK_DELETE';
export class BankDelete implements Action {
  readonly type = BANK_DELETE;
  constructor(public bank: Bank, public reloadAction: BankGetList) {
  }
}

export type All =
  BankGetList |
  BankGetListSuccess |
  BankGetDetail |
  BankGetDetailSuccess|
  BankSelect |
  BankSelectCancel |
  BankCreate |
  BankModify |
  BankDelete;
