import {Pipe, PipeTransform} from '@angular/core';
/**
 * oh
 * 2018. 02. 21.
 * */
@Pipe({
  name: 'selectStateConvert'
})
export class SelectStateConvert implements PipeTransform {

  transform(value: number): string{
    let state: string;
    switch (value) {
      case -1 :
        state = '결제취소';
        break;
      case 1 :
        state = '결제대기';
        break;
      case 2 :
        state = '입금대기';
        break;
      case 3 :
        state = '결제완료';
        break;
      case 4 :
        state = '배송중';
        break;
      case 5 :
        state = '배송완료';
        break;
      default:
        break;
    }
    return state;
  }
}
