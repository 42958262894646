import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'fuse-quick-panel',
  templateUrl: './quick-panel.component.html',
  styleUrls: ['./quick-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FuseQuickPanelComponent implements OnInit {
  date: Date;
  settings: any;
  notes: any[];
  events: any[];

  constructor() {

    this.date = new Date();
    this.settings = {
      notify: true,
      cloud: false,
      retro: true
    };

    // sample data
    this.notes = [
      {
        'title' : 'Best songs to listen while working',
        'detail': 'Last edit: May 8th, 2015'
      },
      {
        'title' : 'Useful subreddits',
        'detail': 'Last edit: January 12th, 2015'
      }
    ];

    this.events = [
      {
        'title' : 'Group Meeting',
        'detail': 'In 32 Minutes, Room 1B'
      },
      {
        'title' : 'Public Beta Release',
        'detail': '11:00 PM'
      },
      {
        'title' : 'Dinner with David',
        'detail': '17:30 PM'
      },
      {
        'title' : 'Q&A Session',
        'detail': '20:30 PM'
      }
    ];
  }

  ngOnInit() {
    // this.http.get('api/quick-panel-notes')
    //   .subscribe((response: any) => {
    //     this.notes = response;
    //   });
    //
    // this.http.get('api/quick-panel-events')
    //   .subscribe((response: any) => {
    //     this.events = response;
    //   });
  }
}
