import {IFaqState, State} from './state';
import * as FaqActions from './action';
import * as ArticleActions from '../article/action';

export type Action = FaqActions.All;
export const initState: IFaqState = State() as IFaqState;

export function faqReducer(state: IFaqState = initState, action: Action) {
  switch (action.type) {

    case FaqActions.FAQ_GET_LIST_SUCCESS:
      action = action as FaqActions.FaqGetListSuccess;
      return  state.merge({
        total: action.total,
        list: action.list
      });

    case FaqActions.FAQ_SELECT:
    case FaqActions.FAQ_GET_DETAIL_SUCCESS:
      action = action as FaqActions.FaqSelect;
      return state.merge({
        selected: action.faq
      });

    case FaqActions.FAQ_SELECT_CANCEL:
      return state.merge({ selected: null});

    default:
      return state;
  }
}
