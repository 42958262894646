import {IBankState, State} from './state';
import * as BankActions from './action';

export type Action = BankActions.All;
export const initState: IBankState = State() as IBankState;

export function bankReducer(state: IBankState = initState, action: Action) {
  switch (action.type) {

    case BankActions.BANK_GET_LIST_SUCCESS:
      action = action as BankActions.BankGetListSuccess;
      return  state.merge({
        total: action.total,
        list: action.list
      });

    case BankActions.BANK_SELECT:
    case BankActions.BANK_GET_DETAIL_SUCCESS:
      action = action as BankActions.BankSelect;
      return state.merge({
        selected: action.bank
      });

    case BankActions.BANK_SELECT_CANCEL:
      return state.merge({ selected: null});

    default:
      return state;
  }
}
