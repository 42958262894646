import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {IResponse, RESPONSE_CODE} from '../../helper/response';

import * as PaymentActions from './action';
import * as RouterActions from '../../../core/redux/router/router.action';
import {PaymentApiService} from '../../api/payment-api.service';

@Injectable()
export class PaymentEffect {

  @Effect() Cancel$ = this.action$
    .ofType(PaymentActions.PAYMENT_CANCEL)
    .switchMap((action: PaymentActions.PaymentCancel) => {
      return this.paymentApi
        .cancel(action.id)
        .map((res: IResponse<any>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            alert('카드 결제가 취소되었습니다.');
            return new RouterActions.Back();
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });


  constructor(private action$: Actions, private paymentApi: PaymentApiService) {
  }
}
