import {IModel} from './interface';
import {Serializable} from '../helper/serializable';

export class Found extends Serializable implements IModel {
  _id: string;
  title: string;
  contents: string;
  position: string;
  name: string;
  contact: string;
  call: boolean;
  msg: boolean;
  imgs: any[];
  find_date: string;
  report: any;
  date: Date = new Date();
  writer: {_id: string, name: string};
  constructor(
    _id?: string,
    title?: string,
    contents?: string,
    name?: string,
    contact?: string,
    imgs?: any[],
    date?: Date,
    writer?: {_id: string, name: string}
  ) {
    super();
    this._id = _id;
    this.title = title;
    this.contents = contents;
    this.name = name;
    this.contact = contact;
    this.imgs = imgs;
    this.date = date;
    this.writer = writer;
  }

  toObject(): object {
    return {
      _id: this._id,
      title: this.title,
      contents: this.contents,
      name: this.name,
      contact: this.contact,
      images: this.imgs,
      date: this.date,
      writer: this.writer
    };
  }
}

