import {NgModule} from '@angular/core';
import {UserApiService} from './user-api.service';
import {ArticleApiService} from './article-api.service';
import {MembershipApiService} from './membership-api.service';
import {ClueApiService} from './clue-api.service';
import {FaqApiService} from './faq-api.service';
import {FoundApiService} from './found-api.service';
import {OrderApiService} from './order-api.service';
import {BankApiService} from './bank-api.service';
import {ProductApiService} from './product-api.service';
import {InquiryApiService} from './inquiry-api.service';
import {PaymentApiService} from './payment-api.service';
import {AwsApiService} from './aws-api.service';
import {BannerApiService} from './banner-api.service';

@NgModule({
  providers: [
    UserApiService,
    ArticleApiService,
    MembershipApiService,
    ClueApiService,
    FaqApiService,
    FoundApiService,
    OrderApiService,
    BankApiService,
    InquiryApiService,
    PaymentApiService,
    ProductApiService,
    AwsApiService,
    BannerApiService
  ]
})
export class ApiModule {}
