import {NgModule} from '@angular/core';

import {SharedModule} from '../../shared/shared.module';
import {FuseFooterComponent} from './footer.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    FuseFooterComponent
  ],
  exports: [
    FuseFooterComponent
  ]
})
export class FuseFooterModule { }

