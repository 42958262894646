export const navigation = [
  {
    'id': 'menus',
    'title': 'Menus',
    'translate': 'NAV.MENU',
    'type': 'group',
    'icon': 'apps',
    'children': [
      {
        'id': 'dashboards',
        'title': 'Dashboards',
        'translate': 'NAV.DASHBOARDS',
        'type': 'item',
        'icon': 'dashboard',
        'url': '/dashboard'
      },
      {
        'id': 'notices',
        'title': 'Notice',
        'translate': 'NAV.NOTICE',
        'type': 'item',
        'icon': 'announcement',
        'url': '/notice'
      },
      {
        'id': 'banner',
        'title': 'Banner',
        'translate': 'NAV.BANNER',
        'type': 'item',
        'icon': 'announcement',
        'url': '/banner'
      },
      {
        'id': 'users',
        'title': 'User',
        'translate': 'NAV.USER',
        'type': 'item',
        'icon': 'person',
        'url': '/user'
      },
      {
        'id': 'inquiries',
        'title': 'INQUIRY',
        'translate': 'NAV.INQUIRY',
        'type': 'item',
        'icon': 'question_answer',
        'url': '/inquiry'
      },
      {
        'id': 'faqs',
        'title': 'FAQs',
        'translate': 'NAV.FAQS',
        'type': 'item',
        'icon': 'question_answer',
        'url': '/faq'
      },
      {
        'id': 'usages',
        'title': 'USAGES',
        'translate': 'NAV.USAGE',
        'type': 'item',
        'icon': 'question_answer',
        'url': '/usage'
      },
      {
        'id': 'found',
        'title': 'Found',
        'translate': 'NAV.FOUND',
        'type': 'item',
        'icon': 'library_books',
        'url': '/found'
      },
      {
        'id': 'clue',
        'title': 'Clue',
        'translate': 'NAV.CLUE',
        'type': 'item',
        'icon': 'library_books',
        'url': '/clue'
      },
      {
        'id': 'product',
        'title': 'Product',
        'translate': 'NAV.PRODUCT',
        'type': 'item',
        'icon': 'shopping_cart',
        'url': '/product'
      },
      {
        'id': 'order',
        'title': 'Order',
        'translate': 'NAV.ORDER',
        'type': 'item',
        'icon': 'shopping_cart',
        'url': '/order'
      },
      {
        'id': 'bank',
        'title': 'Bank',
        'translate': 'NAV.BANK',
        'type': 'item',
        'icon': 'shopping_cart',
        'url': '/bank'
      }
    ]
  }
];
