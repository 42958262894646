import {Injectable} from '@angular/core';
import {HttpService} from '../service/aram/http.service';
import {BaseService} from './base.service';
import {Observable} from 'rxjs';
import {IResponse} from '../helper/response';

@Injectable()
export class MembershipApiService extends BaseService {
  constructor(http: HttpService) {
    super('sign', http);
  }

  signin(id: string, pass: string): Observable<IResponse<any>> {
    const params = {
      id: id,
      pass: pass
    };
    return this.http.post(`${this.controllerName}/in`, params);
  }

  logout(): Observable<IResponse<any>> {
    return this.http.delete(`${this.controllerName}/`, {});
  }

}
