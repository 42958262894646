import {NgModule} from '@angular/core';
import {DaumService} from './daum.service';
import {DialogService} from './dialog.service';
import {HttpService} from './http.service';
import {SessionService} from './session.service';
import {SummernoteService} from './summernote.service';

@NgModule({
  providers: [
    DaumService,
    DialogService,
    HttpService,
    SessionService,
    SummernoteService
  ]
})
export class AramServiceModule { }

