import {Serializable} from '../helper/serializable';
import {IModel} from './interface';

export class Faq extends Serializable implements IModel {

  _id: string;
  category: number;
  title: string;
  question: any;
  answer: any;
  images: any[];

  constructor(
    _id?: string,
    category?: number,
    title?: string,
    question?: any,
    answer?: any,
    images?: any[]
  ) {
    super();
    this._id = _id;
    this.category = category;
    this.title = title;
    this.question = question;
    this.answer = answer;
    this.images = images;
  }

  toObject(): object {
    return {
      _id: this._id,
      category: this.category,
      title: this.title,
      question: this.question,
      answer: this.answer,
      images: this.images
    };
  }
}
