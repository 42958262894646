import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

const appRoutes: Routes = [
  {
    path: 'membership',
    loadChildren: './main/content/membership/membership.module#MembershipModule'
  },
  {
    path: 'dashboard',
    loadChildren: './main/content/dashboard/dashboard.module#DashboardModule'
  },
  {
    path: 'notice',
    loadChildren: './main/content/notice/notice.module#NoticeModule'
  },
  {
    path: 'banner',
    loadChildren: './main/content/banner/banner.module#BannerModule'
  },
  {
    path: 'user',
    loadChildren: './main/content/user/user.module#UserModule'
  },
  {
    path: 'inquiry',
    loadChildren: './main/content/inquiry/inquiry.module#InquiryModule'
  },
  {
    path: 'faq',
    loadChildren: './main/content/faq/faq.module#FaqModule'
  },
  {
    path: 'usage',
    loadChildren: './main/content/usage/usage.module#UsageModule'
  },
  {
    path: 'found',
    loadChildren: './main/content/found/found.module#FoundModule'
  },
  {
    path: 'clue',
    loadChildren: './main/content/clue/clue.module#ClueModule'
  },
  {
    path: 'order',
    loadChildren: './main/content/order/order.module#OrderModule'
  },
  {
    path: 'product',
    loadChildren: './main/content/product/product.module#ProductModule'
  },
  {
    path: 'bank',
    loadChildren: './main/content/bank/bank.module#BankModule'
  },
  {
    path: '**',
    redirectTo: 'membership'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {

}
