import {Component, EventEmitter, Output} from '@angular/core';
import {Subscription} from 'rxjs';

import {FuseConfigService} from '../../../core/service/fuse/config.service';

@Component({
  selector: 'fuse-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class FuseSearchBarComponent {
  collapsed: boolean;
  toolbarColor: string;
  @Output() onInput: EventEmitter<any> = new EventEmitter();
  onConfigChanged: Subscription;

  constructor(
    private fuseConfig: FuseConfigService
  ) {
    this.collapsed = true;
    this.onConfigChanged =
      this.fuseConfig.onConfigChanged
        .subscribe(
          (newSettings) => {
            this.toolbarColor = newSettings.colorClasses.toolbar;
          }
        );
  }

  collapse() {
    this.collapsed = true;
  }

  expand() {
    this.collapsed = false;
  }

  search(event) {
    const value = event.target.value;

    this.onInput.emit(value);
  }

}
