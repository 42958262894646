import {Action} from '@ngrx/store';
import {Order} from '../../model/order';

export const ORDER_GET_LIST = 'ORDER_GET_LIST';
export class OrderGetList implements Action {
  readonly type = ORDER_GET_LIST;
  constructor(public offset: number, public count: number, public keyword?: any) { }
}

export const ORDER_GET_LIST_SUCCESS = 'ORDER_GET_LIST_SUCCESS';
export class OrderGetListSuccess implements Action {
  readonly type = ORDER_GET_LIST_SUCCESS;
  constructor(public total: number, public list: Order[]) { }
}

export const ORDER_GET_DETAIL = 'ORDER_GET_DETAIL';
export class OrderGetDetail implements Action {
  readonly type = ORDER_GET_DETAIL;
  constructor(public id: string) {
  }
}

export const ORDER_GET_DETAIL_SUCCESS = 'ORDER_GET_DETAIL_SUCCESS';
export class OrderGetDetailSuccess implements Action {
  readonly type = ORDER_GET_DETAIL_SUCCESS;
  constructor(public order: Order) {
  }
}

export const ORDER_SELECT = 'ORDER_SELECT';
export class OrderSelect implements Action {
  readonly type = ORDER_SELECT;
  constructor(public order: Order) { }
}

export const ORDER_SELECT_CANCEL = 'ORDER_SELECT_CANCEL';
export class OrderSelectCancel implements Action {
  readonly type = ORDER_SELECT_CANCEL;
  constructor() { }
}

export const ORDER_DELETE = 'ORDER_DELETE';
export class OrderDelete implements Action {
  readonly type = ORDER_DELETE;
  constructor(public order: Order, public reloadAction: OrderGetList) {}
}

export const ORDER_MODIFY = 'ORDER_MODIFY';
export class OrderModify implements Action {
  readonly type = ORDER_MODIFY;
  constructor(public order: Order) {}
}

export type All =
  OrderGetList |
  OrderGetListSuccess |
  OrderDelete |
  OrderGetDetail |
  OrderGetDetailSuccess |
  OrderSelect |
  OrderSelectCancel
  ;
