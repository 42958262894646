import {Injectable} from '@angular/core';
import {Actions, Effect} from '@ngrx/effects';
import {IResponse, RESPONSE_CODE} from '../../helper/response';

import * as ProductActions from './action';
import * as RouterActions from '../router/router.action';
import {Converter} from '../../helper/converter';
import {ProductApiService} from '../../api/product-api.service';
import {Product} from '../../model/product';

@Injectable()
export class ProductEffect {

  @Effect() ProductList$ = this.action$
    .ofType(ProductActions.PRODUCT_GET_LIST)
    .switchMap((action: ProductActions.ProductGetList) => {
      return this.productApi
        .list(action.offset, action.count, action.keyword)
        .map((res: IResponse<Product[]>) => {
          if (res.code === RESPONSE_CODE.SUCCESS) {
            const orders: Product[] = Converter.jsonToInstance<Product>(Product, res.data.list);
            return new ProductActions.ProductGetListSuccess(res.data.total, orders);
          } else {
            return {type: 'NULL_ACTION'};
          }
        });
    });

  constructor(private action$: Actions, private productApi: ProductApiService) {
  }
}
