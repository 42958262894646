import {AppStore} from '../../../app-store.interface';
import {createSelector} from '@ngrx/store';
import {IFaqState} from './state';

export const baseFaq = (state: AppStore) => state.faq;

export const getList = createSelector(baseFaq, (state: IFaqState) => state.list);
export const getTotal = createSelector(baseFaq, (state: IFaqState) => state.total);
export const getSelected = createSelector(baseFaq, (state: IFaqState) => state.selected);

